import React, { useState } from 'react';
import Header from "../../components/Header";
import {
    Route,
    BrowserRouter as Router,
    Routes,
    Link
} from "react-router-dom";

import About from "./pages/Home/About";
import ContactUs from "./pages/Home/ContactUs";
import Home from "./pages/Home/Home";
import HomeNav from '../../components/HomeNav';
import Login from '../common/pages/Login/Login';
import MyTheme from '../../components/MyTheme';
import PartyPlaces from "./pages/PartyPlaces/PartyPlaces";
import PartyPlace from './pages/PartyPlace/PartyPlace';
import PWResetInvalid from '../common/pages/Login/PWResetInvalid';
import PWReset from '../common/pages/Login/PWReset';
import PWResetPW from '../common/pages/Login/PWResetPW';
import PWResetSent from '../common/pages/Login/PWResetSent';
import Unauthorized from '../common/pages/Unauthorized/Unauthorized';
import VerifyFail from '../common/pages/Login/VerifyFail';
import VerifyLogin from '../common/pages/Login/VerifyLogin';
import { IUserBasic } from '../../models/IUser';
import { Page404 } from '../../components/Page404';
import { ProtectLogin } from '../../components/ProtectLogin';
import { SiteKey } from '../../models/Site';
import { Link as CarbonLink } from '@carbon/react';
import './SitePartyTaken.scss';
import Accounts from './pages/Accounts/Accounts';
import NewAccount from './pages/NewAccount/NewAccount';
import Account from './pages/Account/Account';
import { Reservation } from "./pages/Reservation/Reservation";
import { Reservations } from './pages/Reservations/Reservations';
import UserV1 from '../../services/UserV1';
import MDContent from '../../components/MDContent';
import { Grid } from '@carbon/react';
import { Column } from '@carbon/react';
import { Accounting } from './pages/Accounting/Accounting';

// const PageNotImplemented: React.FC = () => {
//     return (<div>PageNotImplemented</div>);
// }

const SitePartyTaken: React.FC = () => {
    document.title="PartyTaken";
    const [user, setUser] = useState<IUserBasic | undefined>(undefined);
    const [, setInit] = useState<boolean>(false);

    UserV1.addUserListener((newUser) => {
        setUser(newUser);
    })
    UserV1.getUser().then(newUser => {
        if (JSON.stringify(newUser) !== JSON.stringify(user)) {
            setUser(newUser);
            setInit(true);
        }
    });

    let siteKey: SiteKey = "pt";
    // let local = document.location.href.includes("localhost") || document.location.href.includes("127.0.0.1");

    return (<>
        <MyTheme siteKey={siteKey} theme="g10" style={{ minHeight: "calc(100vh - 4.5rem)"}}>
            <Router>
                <Header>
                    <Link className="clean" to="/">Home</Link>
                    <span className="spacer">|</span>
                    <Link className="clean" to="/about">About</Link>
                    <span className="spacer">|</span>
                    <Link className="clean" to="/contactus">Contact Us</Link>
                </Header>
                <HomeNav siteKey={siteKey}></HomeNav>
                {/* {!user && !local && <ToastNotification
                    style={{ position: "absolute", left: "calc(50vw - 10rem)", top: "20rem", zIndex: "1000" }}
                    hideCloseButton={false}
                    statusIconDescription="notification"
                    subtitle="This site is not yet live. If you own an event venue and
                        want to list with us, contact us at info@partytaken.com"
                    title="Coming soon!"
                />} */}
                <Routes>
                    <Route path="/" element={ <Home /> } />
                    <Route path="/content/*" element={<MDContent siteKey={siteKey} />} />

                    {/* AUTH ROUTES */}
                    <Route path="/auth/reservations" element={
                        <ProtectLogin>
                            <Reservations user={user} />
                        </ProtectLogin>
                    } />
                    <Route path="/auth/reservation/:id" element={
                        <ProtectLogin>
                            <Reservation user={user} />
                        </ProtectLogin>
                    } />
                    <Route path="/auth/partyplace" element={
                        <ProtectLogin>
                            <PartyPlace loggedIn={!!user}/>
                        </ProtectLogin>
                    } />

                    <Route path="/contactus" element={
                        <ProtectLogin>
                            <ContactUs/>
                        </ProtectLogin>
                    } />

                    {/* ADMIN ROUTES */}
                    <Route path="/account/accounts" element={
                        <ProtectLogin roles={["ADMIN", "OWNER", "MANAGER", "STAFF"]}>
                            <Accounts />
                        </ProtectLogin>
                    } />
                    <Route path="/account/new_account" element={
                        <ProtectLogin roles={["ADMIN", "OWNER"]}>
                            <NewAccount />
                        </ProtectLogin>
                    } />
                    <Route path="/account/home/:id/*" element={
                        <ProtectLogin roles={["ADMIN", "OWNER", "MANAGER", "STAFF"]}>
                            <Account user={user} />
                        </ProtectLogin>
                    } />

                    <Route path="/accounting" element={
                        <ProtectLogin roles={["ADMIN", "OWNER", "MANAGER", "STAFF"]}>
                            <Accounting user={user} />
                        </ProtectLogin>
                    } />
                    <Route path="/account/home/:accountId/accounting" element={
                        <ProtectLogin roles={["ADMIN", "OWNER", "MANAGER", "STAFF"]}>
                            <Accounting user={user} />
                        </ProtectLogin>
                    } />
                    <Route path="/account/home/:accountId/location/home/:locationId/accounting" element={
                        <ProtectLogin roles={["ADMIN", "OWNER", "MANAGER", "STAFF"]}>
                            <Accounting user={user} />
                        </ProtectLogin>
                    } />

                    {/* LOGIN/PW ROUTES */}
                    <Route path="/login" element={<Login siteKey={siteKey} /> } />
                    <Route path="/pwreset" element={<PWReset siteKey={siteKey} />} />
                    <Route path="/pwreset_sent" element={<PWResetSent/>} />
                    <Route path="/pwreset_invalid" element={<PWResetInvalid/>} />
                    <Route path="/pwreset_pw" element={<PWResetPW/>} />
                    <Route path="/unauthorized" element={<Unauthorized />} />
                    <Route path="/verifying" element={<VerifyLogin siteKey={siteKey} />} />
                    <Route path="/verify_fail" element={<VerifyFail siteKey={siteKey} />} />

                    {/* PUBLIC ROUTES */}
                    <Route path="/about" element={<About />} />
                    <Route path="/partyplaces" element={<PartyPlaces /> } />
                    <Route path="/partyplace" element={<PartyPlace loggedIn={!!user} />} />

                    <Route path="/*" element={<Page404 /> } />
                </Routes>
            </Router>
            <div style={{ paddingTop: "0rem" }} />
        </MyTheme>
        <div className="ptHomeFooter">
            <Grid>
                <Column sm={4} md={8} lg={16}>
                    <CarbonLink href={`/content/terms`} style={{color: "black"}}>
                        Terms of service
                    </CarbonLink>
                    &nbsp;|&nbsp;
                    <CarbonLink href={`/content/privacy`} style={{color: "black"}}>
                        Privacy Policy
                    </CarbonLink>
                </Column>
            </Grid>
            <Grid style={{marginTop: ".5rem"}}>
                <Column sm={4} md={8} lg={16}>
                <span style={{fontSize: "var(--cds-body-compact-01-font-size,.875rem)"}}>PartyTaken, LLC dba partytaken.com</span>
                </Column>
            </Grid>
        </div>
        </>
    );
}

export default SitePartyTaken;
