import React from 'react';
import { Grid, Column } from "@carbon/react";

import './Header.scss';
import { Link } from 'react-router-dom';
import MyTheme from './MyTheme';
import { Config } from '../Config';

interface HeaderProps {
}

interface HeaderState {
}

export default class Header extends React.Component<HeaderProps, HeaderState> {

    render() {
        let siteKey = Config.getSiteKey();
        return (
            <MyTheme siteKey={siteKey!} theme="brand">
                <header aria-label={ siteKey === "pt" ? "PartyTaken" : "CampTaken" }>
                    <Grid>
                        <Column sm={4} md={2} lg={8} style={{marginTop: "1rem"}}>
                            <span className="name">
                                { siteKey === "pt" && 
                                    <Link to="/">Party<span style={{ fontWeight: "bold" }}>Taken</span></Link>}
                                { siteKey === "ct" && 
                                    <Link to="/">Camp<span style={{ fontWeight: "bold" }}>Taken</span></Link>}
                            </span>
                        </Column>
                        <Column sm={4} md={6} lg={8} className="rightNav" style={{marginTop: "1.2rem", marginBottom: "1rem"}}>
                            <Link className="clean" to="/">Home</Link>
                            <span className="spacer">|</span>
                            <Link className="clean" to="/content/about">About</Link>
                            <span className="spacer">|</span>
                            <Link className="clean" to="/content/contactus">Contact Us</Link>
                        </Column>
                    </Grid>
                </header>
            </MyTheme>
        );
    }
}
