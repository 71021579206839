import React from 'react';

import { 
    Button, 
    Column, 
    Grid
} from "@carbon/react";
import "./Accounts.scss";
import { AccountV1, IPTAccount } from '../../services/AccountV1';
import SimpleTable from '../../../../components/SimpleTable';
import { Add, TrashCan, FolderOpen } from '@carbon/react/icons';
import { ModalConfirmDelete } from '../../../../components/ModalConfirmDelete';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { IUserBasic } from '../../../../models/IUser';
import UserV1 from '../../../../services/UserV1';
import { ButtonSet } from '@carbon/react';

interface AccountsState {
    accounts?: IPTAccount[]
    user?: IUserBasic
    confirmDelete?: {
        id: string,
        name: string
    }
    confirmDeleteTxt: string
}

class AccountsCls extends React.Component<{ navigate: NavigateFunction }, AccountsState> {
    state: AccountsState = {
        confirmDeleteTxt: ""
    }

    componentDidMount() {
        // document.body.style.backgroundColor = "#eee";
        (async () => {
            await this.refresh();
        })();
    }

    componentWillUnmount() {
        // document.body.style.backgroundColor = "inherit";
    }

    async refresh() {
        this.setState({ accounts: undefined })
        let accounts = await AccountV1.getAccounts();
        let user = await UserV1.getUser();
        this.setState({ accounts, user });
    }

    render() {
        try {
            return (
                <>
                    <main className="Accounts">
                        {(
                        this.state.user && (
                            this.state.user.user_type.includes("ADMIN")
                        )
                            ) && (<>
                                <div style={{ marginTop: "1rem" }} />
                                <Grid>
                                    <Column sm={4} md={4} lg={6}>
                                        <ButtonSet>
                                            <Button kind="secondary" href={`/accounting`}>Accounting</Button>
                                        </ButtonSet>
                                    </Column>
                                </Grid>
                            </>)}
                        <Grid>
                            <Column sm={4} md={8} lg={12}>
                                <h1>Accounts</h1>
                                <SimpleTable
                                    noRecordsText="No accounts found"
                                    headers={[
                                        { key: "name", header: "Account Name" },
                                        { key: "urlname", header: "URL part" },
                                        { key: "actions", header: "Actions" }
                                    ]}
                                    actions={(this.state.user?.user_type.includes("ADMIN") && <Button renderIcon={Add} onClick={() => {
                                        this.props.navigate("/account/new_account");
                                    }}>New account</Button>) || <></>}
                                    rows={this.state.accounts}
                                    onClick={(rowId) => {
                                        this.props.navigate(`/account/home/${rowId}`);
                                    }}
                                    cellMapper={(rowId, cellId, value) => {
                                        if (cellId === "actions") {
                                            return (<div style={{marginLeft: "-1rem"}}>
                                                <Button
                                                    kind="ghost"
                                                    hasIconOnly
                                                    iconDescription="Edit account"
                                                    renderIcon={FolderOpen}
                                                    onClick={async () => {
                                                        this.props.navigate(`/account/home/${rowId}`);
                                                    }}
                                                />
                                                <Button
                                                    kind="ghost"
                                                    hasIconOnly
                                                    iconDescription="Delete"
                                                    renderIcon={TrashCan}
                                                    onClick={async (evt) => {
                                                        this.setState({confirmDelete: {
                                                            id: rowId,
                                                            name: this.state.accounts?.filter(account => account.id === rowId)[0].name!
                                                        }})
                                                        evt.stopPropagation();
                                                    }}
                                                />
                                            </div>);
                                        }
                                        return value;
                                    }}
                                />
                            </Column>
                        </Grid>
                        { this.state.confirmDelete && <ModalConfirmDelete
                            open={!!this.state.confirmDelete}
                            confirmText={this.state.confirmDelete.name}
                            onClose={async () => {
                                this.setState({ confirmDelete: undefined });
                            }}
                            onDelete={async () => {
                                await AccountV1.deleteAccount(this.state.confirmDelete!.id);
                                this.refresh();
                            }}
                        />}
                    </main>
                </>)
        } catch (err) {
            console.error(err);
            return <></>
        }
    }
}

export default function Accounts() {
    let navigate = useNavigate();
    return <AccountsCls navigate={navigate} />
}