import React from 'react';

import {
    TextInput
} from "@carbon/react";
import ModalBasic from './ModalBasic';

interface ModalConfirmDeleteProps {
    open: boolean
    heading?: string
    modalLabel?: string
    confirmText?: string
    onClose: () => Promise<void>
    onDelete: () => Promise<void>
}

interface ModalConfirmDeleteState {
    currentText: string
}

export class ModalConfirmDelete extends React.Component<ModalConfirmDeleteProps, ModalConfirmDeleteState> {
    state: ModalConfirmDeleteState = {
        currentText: ""
    }

    render() {
        let { children, heading, ...rest } = this.props;
        return <ModalBasic 
            danger
            heading={heading || "Are you sure you want to delete?"}
            primaryButtonText="Delete"
            isDisabled={() => (this.state.currentText || "") !== (this.props.confirmText || "")}
            onSubmit={this.props.onDelete}
            onReset={async () => {
                this.setState({ currentText: "" });
            }}
            { ...rest }
        >
            {this.props.confirmText && this.props.confirmText.length > 0 && <TextInput
                id="confirmDeleteTxt"
                data-modal-primary-focus
                labelText="Confirmation text"
                helperText={`Type "${this.props.confirmText}" to confirm`}
                placeholder={this.props.confirmText}
                value={this.state.currentText}
                onChange={(evt) => { this.setState({ currentText: evt.target.value }) }}
                onKeyDown={async (evt: KeyboardEvent) => {
                    if (evt.key === "Enter") {
                        if (this.props.confirmText === this.state.currentText) {
                            await this.props.onDelete();
                            this.props.onClose();            
                        }
                    }
                }}
            />}
        </ModalBasic>
    }
}