import React from 'react';
import { CircularProgress } from '@mui/material';
import Markdown from 'markdown-to-jsx'; 

import PTAbout from '../content/PTAbout.md'
import PTContactUs from '../content/PTContactUs.md'
import PTOverview from '../content/PTOverview.md'
import PTPrivacy from '../content/PTPrivacy.md'
import PTTerms from '../content/PTTerms.md'
import './MDContent.scss';
import { Helmet } from "react-helmet";
import { Location, useLocation } from 'react-router-dom';
import { Grid } from '@carbon/react';
import { Column } from '@carbon/react';
import { SiteKey } from '../models/Site';
import { ListItem } from '@carbon/react';
import { UnorderedList } from '@carbon/react';
import { OrderedList } from '@carbon/react';

const pageMap : { [siteKey: string]: { [key: string]: {title: string, page: any} } } = {
    "pt": {
        "/content/overview": { title: "Overview - PartyTaken", page: PTOverview },
        "/content/about": { title: "About - PartyTaken", page: PTAbout},
        "/content/contactus": { title: "About - ContactUs", page: PTContactUs},
        "/content/terms": { title: "Terms - PartyTaken", page: PTTerms},
        "/content/privacy": { title: "Privacy - PartyTaken", page: PTPrivacy}
    }
}

const MDOPTIONS = {
  overrides: {
    ol: {
        component: OrderedList,
        props: {
            style: { marginLeft: "1.5rem" }
        }
    },
    ul: {
        component: UnorderedList,
        props: {
            style: { marginLeft: "1rem" }
        }
    },
    li: {
      component: ListItem,
    //   props: {
    //     className: 'foo',
    //   },
    },
  },
}


interface MDContentState {
    md: string | null
}

class MDContentCls extends React.Component<{location: Location, siteKey: SiteKey}, MDContentState> {
    state : MDContentState = {
        md: null
    }

    async componentDidMount() {
        let pageUrl = pageMap[this.props.siteKey][this.props.location.pathname];
        if (pageUrl === undefined) {
            this.setState({ md: "Page not found."});
        } else {
            let response = await fetch(pageUrl.page);
            let md = await response.text();
            this.setState({ md: md });
        }
    }

    async componentDidUpdate(prevProps: Readonly<{ location: Location; siteKey: SiteKey; }>, prevState: Readonly<MDContentState>, snapshot?: any): Promise<void> {
        if (prevProps.location.pathname !== this.props.location.pathname || prevProps.siteKey !== this.props.siteKey) {
            let pageUrl = pageMap[this.props.siteKey][this.props.location.pathname];
            if (pageUrl === undefined) {
                this.setState({ md: "Page not found."});
            } else {
                let response = await fetch(pageUrl.page);
                let md = await response.text();
                this.setState({ md: md });
            }
        }
    }

    render() {
        let page = pageMap[this.props.siteKey][this.props.location.pathname];

        return <>
            <Helmet>
                <title>{page.title}</title>
            </Helmet>
            <Grid className="mdContent">
                <Column sm={4} md={6} lg={10}>
                    <div style={{marginTop: "1rem"}}></div>
                    { !this.state.md && <CircularProgress /> }
                    { this.state.md && <Markdown children={this.state.md} options={MDOPTIONS}></Markdown>}
                    <div style={{marginTop: "1rem"}}></div>
                </Column>
            </Grid>
        </>
    }
}
export default function MDContent(props: {siteKey: SiteKey}) {
    let location = useLocation();
    return <MDContentCls siteKey={props.siteKey} location={location} />
}