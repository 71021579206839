import { SiteKey } from "./models/Site";

export class Config {
    public static siteInfoMap = {
        "hrp": {
            siteKey: "hrp",
            title: "Homeroom Parent"
        },
        "club": {
            siteKey: "club",
            title: "Club and Booster"
        },
        "pt": {
            siteKey: "pt",
            title: "PartyTaken"
        },
        "ct": {
            siteKey: "ct",
            title: "CampTaken"
        }
    }

    public static getSiteKey() : SiteKey | undefined {
        if (document.location.href.includes("9043") || document.location.href.includes("homeroomparent.com")) {
            return "hrp";
        } else if (document.location.href.includes("9044") || document.location.href.includes("clubandbooster.com")) {
            return "club";
        } else if (document.location.href.includes("9045") || document.location.href.includes("partytaken.com")) {
            return "pt";
        } else if (document.location.href.includes("9046") || document.location.href.includes("camptaken.com")) {
            return "ct";
        } else {
            return;
        }    
    }
}