import React from 'react';
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import { URLSearchParams } from 'url';
import {
    Breadcrumb, BreadcrumbItem, BreadcrumbSkeleton,
    Column,
    Grid,
    Layer,
    Loading,
    Modal,
    Tile
} from '@carbon/react';

import { SearchV1, IPTSearchResult } from '../../services/SearchV1';
import "./PartyPlace.scss";
import { Booking } from './Booking';
import { IPTLocation, LocationV1 } from '../../services/LocationV1';

interface PartyPlaceClsState {
    init: boolean,
    zip: string,
    minGuests: string,
    event_types: string[]
    locationId?: string,
    searchResult?: IPTSearchResult | null,
    date: string,
    timeStr?: string | null,
    resQuery: string,
    location?: IPTLocation
    highlightImage: number
}

class PartyPlaceCls extends React.Component<{ navigate: NavigateFunction, searchParams: URLSearchParams, loggedIn: boolean }, PartyPlaceClsState> {
    state: PartyPlaceClsState = {
        init: false,
        zip: "",
        date: "",
        minGuests: "",
        event_types: [],
        resQuery: "",
        highlightImage: -1
    }

    async componentDidMount() {
        let sp = this.props.searchParams;
        let eventStr = sp.get("type") ?? "";
        let locationId = sp.get("location") ?? "";
        let timeStr = sp.get("time") || null;
        if (timeStr === "null") timeStr = null;
        let location = await LocationV1.getLocation(locationId);
        this.setState({
            zip: sp.get("zip") ?? "",
            date: sp.get("date") ?? "",
            minGuests: sp.get("guests") ?? "",
            event_types: eventStr.length === 0 ? [] : eventStr.split(","),
            locationId: locationId,
            init: true,
            timeStr,
            location
        });
        // document.getElementById("root")!.style.backgroundColor = "#eee";
        // this.refresh();
    }

    componentDidUpdate(prevProps: Readonly<{ navigate: NavigateFunction; searchParams: URLSearchParams; }>, prevState: Readonly<PartyPlaceClsState>, snapshot?: any): void {
        if (this.state.date !== prevState.date) {
            this.refresh();
        }
    }

    async refresh() {
        let results = await SearchV1.search(this.state.zip, this.state.date, this.state.minGuests, this.state.event_types);
        let searchResult = results.filter(result => result && result.location_id === this.state.locationId)?.[0] || null;
        this.setState({ searchResult });
    }

    render() {
        let qStr = `?zip=${this.state.zip}&date=${this.state.date}&guests=${this.state.minGuests}&type=${encodeURIComponent(this.state.event_types?.join(",") || "")}`;
        let loc = this.state.location;
        let sr = this.state.searchResult;
        let address = (loc ? `${loc.address}
${loc.city}${loc.city && loc.city.length > 0?", ":""}${loc.state} ${loc.zip}
${loc.email}
${loc.phone}` : "").trim().replace(/\n\s*\n/g, "\n");

        return <main className="PartyPlace">
            <div style={{ marginTop: "1rem" }} />
            <Grid>
                <Column sm={4} md={8} lg={16}>
                    {(!loc || typeof sr === "undefined") && <>
                        <BreadcrumbSkeleton />
                        <Loading />
                    </>}
                    {loc && typeof sr !== "undefined" && <>
                        <Breadcrumb>
                            <BreadcrumbItem href={`/partyplaces${qStr}`} >Search results</BreadcrumbItem>
                            <BreadcrumbItem isCurrentPage>{loc.name}</BreadcrumbItem>
                        </Breadcrumb>
                        <h1>{loc.name}</h1>
                        <Grid>
                            <Column sm={4} md={4} lg={8}>
                                <h2>Description</h2>
                                <p>{loc.description.split("\n").map(line => <>{line}<br /></>)}</p>
                                <h2>Location & Contact</h2>
                                <p>{address.split("\n").map(line => <>{line}<br /></>)}</p>
                                <div style={{ marginBottom: "1rem" }} />
                                { loc?.locImages?.[0] && <>
                                    {loc.locImages.map((locImage, locIndex) => (
                                        <img
                                            role="button"
                                            style={{ 
                                                margin: "0rem 1rem 1rem 0rem",
                                                width: "12rem", height: "9rem", 
                                                objectFit: "cover", objectPosition: "left top"
                                            }}
                                            src={`/api/pt/image/${locImage.src}`} alt=""
                                            onClick={() => {
                                                this.setState({ highlightImage: locIndex});
                                            }}
                                        />)
                                    )}
                                </>}
                            </Column>
                            <Column sm={4} md={4} lg={6}>
                                <Tile style={{ margin: "0rem -1rem" }}>
                                    <Layer>
                                        <Booking
                                            loggedIn={this.props.loggedIn}
                                            searchParams={this.props.searchParams}
                                            date={this.state.date}
                                            minGuests={this.state.minGuests}
                                            onDate={(dateStr: string) => {
                                                this.setState({ date: dateStr });
                                                this.props.navigate(`?zip=${this.state.zip}&guests=${this.state.minGuests}&date=${dateStr}&type=${encodeURIComponent(this.state.event_types!.join(",") || "")}&location=${this.state.locationId}${this.state.resQuery}`)
                                            }}
                                            onTime={(timeStr: string) => {
                                                this.setState({ timeStr })
                                                this.props.navigate(`/partyplace${qStr}&location=${this.state.locationId}&time=${timeStr}${this.state.resQuery}`);
                                            }}
                                            searchResult={sr}
                                            timeStr={this.state.timeStr}
                                            onQuery={(newResQuery) => {
                                                let resQuery = "";
                                                if (newResQuery.length > 0) {
                                                    resQuery = `&${newResQuery}`;
                                                }

                                                this.setState({ resQuery });
                                                this.props.navigate(`/partyplace${qStr}&location=${this.state.locationId}&time=${this.state.timeStr}${resQuery}`);
                                            }}
                                            onReserve={() => {
                                                this.props.navigate(`/reserve${qStr}&location=${this.state.locationId}&time=${this.state.timeStr}${this.state.resQuery}`);
                                            }}
                                            onLogin={() => {
                                                this.props.navigate(`/auth/partyplace${qStr}&location=${this.state.locationId}&time=${this.state.timeStr}${this.state.resQuery}`);
                                            }}
                                        />
                                    </Layer>
                                </Tile>
                                <div style={{ marginBottom: "2rem" }} />
                            </Column>
                        </Grid>
                    </>}
                </Column>
            </Grid>
            {this.state.highlightImage >= 0 && (
                <Modal 
                    open={this.state.highlightImage >= 0} 
                    onRequestClose={() => this.setState({ highlightImage: -1 })} 
                    passiveModal 
                    modalHeading="">
                    <img
                        style={{ 
                            margin: "0rem 1rem 1rem 0rem",
                            objectFit: "cover", objectPosition: "left top"
                        }}
                        src={`/api/pt/image/${this.state.location!.locImages![this.state.highlightImage].src}`} alt=""
                    />
                </Modal>
            )}
        </main>
    }
}

export default function PartyPlace(props: Readonly<{ loggedIn: boolean }>) {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    return <PartyPlaceCls navigate={navigate} searchParams={searchParams} loggedIn={props.loggedIn} />
}
