import React from "react";
import { Grid, Column } from "@carbon/react";
import "./HomeContent.scss";

export default function About() {
    return (
        <div className="ptContent">
            <main>
                <Grid>
                    <Column sm={4} md={6} lg={8}>
                        <h1>PartyTaken Story</h1>
                        {/* <p>PartyTaken is located in Round Rock, Texas. The business was started
                        by a part-time working mom who has three kids. She found 
                        that she was spending countless hours on the phone trying to find 
                        open venues. She noticed that there is a need for a reservation 
                        system that is convenient and easy to access.</p>

                    <p>Also, two good birthday party venues where her kids had parties 
                        closed down due to a lack of business. She knew that both businesses 
                        and customers needed a calendar reservation system that showed times, 
                        prices, and more information so the customer can book parties right 
                        at that moment. This service is to help you, the customer, book events, 
                        and for the venues organization, visibility, and marketing. It is an all 
                        around win-win for everyone.</p> */}
                        <p>
                            PartyTaken, based in Round Rock, Texas, was created by a busy, part-time working mom with
                            three kids who saw an opportunity to simplify party planning for everyone. Like many
                            parents, she spent countless hours searching for available venues for birthday parties and
                            events. She quickly realized how time-consuming and complicated the process was, and that's
                            when the idea for PartyTaken began to take shape.
                        </p>

                        <p>
                            She also noticed a recurring challenge for local businesses: two of her kids' favorite party
                            venues had to close due to low bookings. This sparked the realization that both customers
                            and venues needed a better solution. What was missing was an easy-to-use reservation system
                            that showed availability, prices, and key details, allowing customers to book their events
                            instantly.
                        </p>

                        <p>
                            Thus, PartyTaken was born—a platform designed to help families quickly find and book the
                            perfect event spaces while offering venues a way to stay organized, increase visibility, and
                            grow their business. Whether you're a parent looking to host a memorable event or a venue
                            looking to streamline your booking process, PartyTaken is here to make things easier for
                            everyone.
                        </p>

                        <p>
                            With PartyTaken, customers can effortlessly find and secure the best party locations, while
                            venues enjoy better organization, more exposure, and higher booking rates. It's an
                            all-around win-win for families and businesses alike!
                        </p>
                    </Column>
                </Grid>
            </main>
        </div>
    );
}
