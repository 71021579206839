import {
    Breadcrumb,
    BreadcrumbItem,
    Column,
    Grid,
    SkeletonText
} from "@carbon/react";
import React from "react";
import { NavigateFunction, Params, useNavigate, useParams } from "react-router-dom";
import ReservationV1, { IReservation, Status } from "../../services/ReservationV1";
import { TimeUtil } from "../../../../util/TimeUtil";
import SimpleTable from "../../../../components/SimpleTable";
import { IUserBasic } from "../../../../models/IUser";
import { AccountV1, IPTAccount } from "../../services/AccountV1";
import { IPTLocation, LocationV1 } from "../../services/LocationV1";
import { BookingCls } from "../PartyPlace/Booking";
import { Money } from "@carbon/react/icons";
import { ModalTextInput } from "../../../../components/ModalTextInput";

export interface AccountingProps {
    user?: IUserBasic
}

type PayDataItem = {
    accountId: string,
    accountName: string,
    amount: number,
    rowIds: string[]
}

interface AccountingState {
    accounts?: IPTAccount[]
    locations?: IPTLocation[]
    reservations?: Array<Partial<IReservation>>;
    payReference: string
    payData: Array<PayDataItem>
}

class AccountingCls extends React.Component<
    AccountingProps & { navigate: NavigateFunction, params: Params<string> },
    AccountingState
> {
    state: AccountingState = {
        payReference: "",
        payData: []
    };

    componentDidMount(): void {
        (async () => {
            const accountId = this.props.params["accountId"];
            const locationId = this.props.params["locationId"];
            let reservations = await ReservationV1.getAll();
            
            let accounts: IPTAccount[] = [];
            let locations: IPTLocation[] = [];
            if (locationId) {
                locations = [await LocationV1.getLocation(locationId)];
                accounts = [await AccountV1.getAccount(locations[0].account_id)];
                reservations = reservations.filter(res => res.location_id === locations[0].id)
            } else if (accountId) {
                accounts = [await AccountV1.getAccount(accountId)];
                locations = await LocationV1.getLocations(accountId);
                let accountLocIds = locations.map(loc => loc.id);
                reservations = reservations.filter(res => accountLocIds.includes(res.location_id!));
            } else {
                accounts = await AccountV1.getAccounts();
                for (const account of accounts) {
                    locations = locations.concat(await LocationV1.getLocations(account.id));
                }
            }

            this.setState({
                reservations,
                accounts,
                locations
            });
        })();
    }

    render() {
        // Sort and organize the reservations
        let reservations: Array<Partial<IReservation>>;
        if (this.state.reservations && this.state.reservations.length) {
            reservations = JSON.parse(JSON.stringify(this.state.reservations));
            reservations.sort((b, a) => {
                if (a.date !== b.date) {
                    return (
                        new Date(a.date!).getTime() -
                        new Date(b.date!).getTime()
                    );
                } else {
                    return TimeUtil.compare(a.time!.start, b.time!.start);
                }
            });
        } else {
            reservations = [];
        }

        let resTable: Array<{
            id: string,
            date: string,
            acctId: string,
            acctName: string,
            locName: string,
            serviceFee: number,
            amountOwed: number,
            amountDue: number,
            amountPaidOut: number,
            status: Status
        }> = [];
        for (const res of reservations) {
            let { costs: icosts, downPayments: idownPayments } = 
                BookingCls.calculateCharges(res.package!, res.totalGuests!);

            // Amounts captured by Paypal
            let amountCaptured = res.payments!.reduce((acc, curr) => (
                acc + parseFloat(curr.captureDetails?.amount?.total || "0")
            ), 0);
            // Amounts authorized, but not captured
            let amountAuthorized = res.payments!.reduce((acc, curr) => (
                acc + (curr.captureDetails ? 0 : parseFloat(curr.authDetails?.amount?.total || "0"))
            ), 0);
            // Zero out pending amounts for cancelled or declined reservations
            if (res.status === "CANCELLED" || res.status === "DECLINED") {
                amountAuthorized = 0;
            }
            // Amounts refunded
            let amountRefunded = res.payments!.reduce((acc, curr) => (
                acc + parseFloat(curr.refundDetails?.amount?.total || "0")
            ), 0);
            // Net of payments - refunds
            let amountNet = amountCaptured+amountAuthorized-amountRefunded;
            // Amounts paid by PartyTaken
            let amountPaidOut = (res.ptPayments || []).reduce((acc, curr) => (
                acc + (curr.total || 0)
            ), 0);

            // Service fee for this reservation
            let serviceFee = Math.min(
                amountNet, 
                idownPayments.filter(item => item.label === "Service fee")
                    .reduce((acc, curr) => acc + curr.value, 0)
            );

            let cost = icosts.filter(item => item.label !== "Service fee").reduce((acc, curr) => acc + curr.value, 0);
            if (res.status === "CANCELLED" || res.status === "DECLINED") {
                cost = 0;
            }

            let amountOwed = amountNet-serviceFee-amountPaidOut;
            let amountDue = (cost + serviceFee) - amountNet;

            if (!(cost === 0
                && amountNet === 0))
            {
                let location = this.state.locations?.find(loc => loc.id === res.location_id);
                let account = this.state.accounts?.find(acct => acct.id === location?.account_id);

                resTable.push({
                    id: res.id!,
                    date: res.date || "",
                    acctId: account!.id,
                    acctName: account!.name,
                    locName: location!.name,
                    serviceFee,
                    amountOwed,
                    amountDue,
                    amountPaidOut,
                    status: res.status!
                });    
            }
        }
        let showModal = this.state.payData.length > 0;

        return (<>
            <div style={{ marginTop: "3rem" }} />
            <Grid>
                <Column sm={4} md={8} lg={16}>
                    <Breadcrumb>
                        <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                        { this.state.accounts?.length === 1 && <BreadcrumbItem href={`/account/home/${this.state.accounts[0].id}`}>
                            {this.state.accounts[0].name}
                        </BreadcrumbItem> }
                        { this.state.accounts?.length === 1 && this.state.locations?.length === 1 && <BreadcrumbItem href={`/account/home/${this.state.accounts[0].id}/location/home/${this.state.locations[0].id}`}>
                            {this.state.locations[0].name}
                        </BreadcrumbItem> }
                        <BreadcrumbItem isCurrentPage>Accounting</BreadcrumbItem>
                    </Breadcrumb>
                </Column>
            </Grid>
            <main className="Accounting">
                <Grid>
                    <Column sm={4} md={8} lg={16}>
                        <h1>Accounting</h1>
                        {/* { reservations! && JSON.stringify(reservations![0]) } */}
                        {!this.state.reservations && (
                            <SkeletonText paragraph={true} />
                        )}
                        {this.state.reservations && (
                            <>
                                {reservations!.length === 0 && (
                                    <div>No parties taken.</div>
                                )}
                                {reservations!.length > 0 && (
                                    <SimpleTable
                                        totalRows={true}
                                        noRecordsText=""
                                        headers={[
                                            { key: "date", header: "Reservation Date" },
                                            { key: "acctName", header: "Account" },
                                            { key: "locName", header: "Location" },
                                            { key: "serviceFee", header: "PartyTaken fee" },
                                            { key: "amountOwed", header: "Due from PartyTaken" },
                                            { key: "amountDue", header: "Due from Customer" },
                                            { key: "amountPaidOut", header: "Paid" },
                                            { key: "status", header: "Reservation Status" }
                                        ]}
                                        rows={resTable}
                                        onClick={(rowId) => {
                                            this.props.navigate(`/auth/reservation/${rowId}`);
                                        }}      
                                        cellMapper={(rowId, cellId, value) => {
                                            if (["serviceFee", "amountOwed", "amountDue", "amountPaidOut"].includes(cellId)) {
                                                return "$"+parseFloat(value).toFixed(2);
                                            }
                                            return value;
                                        }}
                                        batchActions={this.props.user?.user_type.includes("ADMIN") ? [
                                            { label: "Pay", icon: Money, onClick: async (rowIds) => {
                                                let payData = resTable.reduce((prevValue: PayDataItem[], row) => {
                                                    // If this row is part of the selection and something is owed
                                                    if (rowIds.includes(row.id) && row.amountOwed > 0) {
                                                        let exist = prevValue.find(item => item.accountId === row.acctId);
                                                        if (exist) {
                                                            exist.rowIds.push(row.id);
                                                            exist.amount += row.amountOwed
                                                        } else {
                                                            prevValue.push({
                                                                accountId: row.acctId,
                                                                accountName: row.acctName,
                                                                amount: row.amountOwed,
                                                                rowIds: [row.id]
                                                            })
                                                        }
                                                    }
                                                    return prevValue;
                                                }, [] as PayDataItem[]);
                                                if (resTable.find(row => rowIds.includes(row.id) && row.amountOwed > 0)) {
                                                    this.setState({ payData });
                                                }
                                            }}
                                        ] : undefined}   
                                    />
                                )}
                            </>
                        )}
                    </Column>
                </Grid>
            </main>
            <ModalTextInput     
                open={ showModal && this.state.payData.length > 0 }
                heading="Provide payment reference id"
                inputLabel="Payment reference id"
                dialogText={showModal ? <>
                    <div><strong>Payment to account</strong>: {this.state.payData[0].accountName} ({this.state.payData[0].accountId})</div>
                    <div><strong>Amount</strong>: ${this.state.payData[0].amount.toFixed(2)}</div>
                </> : undefined}
                onClose={async () => {
                    let newPayData = JSON.parse(JSON.stringify(this.state.payData));
                    newPayData.shift();
                    this.setState({ payData: newPayData });
                }}
                onText={async (val: string) => {
                    let rowIds = this.state.payData[0].rowIds;
                    let updRes = reservations.filter(res => rowIds.includes(res.id!));
                    let updateVals: IReservation[] = [];
                    for (const res of updRes) {
                        let payInfo = resTable.find(row => row.id === res.id);
                        if ((payInfo?.amountOwed || 0) > 0) {
                            res.ptPayments = res.ptPayments || [];
                            res.ptPayments.push({
                                ts: new Date().getTime(),
                                total: payInfo?.amountOwed || 0,
                                ref: val
                            })
                            updateVals.push(res as IReservation);
                        }
                    }
                    if (updateVals.length > 0) {
                        await ReservationV1.pay(updateVals);
                    }
                    document.location.reload();
                }}
            />
        </>);
    }
}

export function Accounting({ user } : { user?: IUserBasic }) {
    let navigate = useNavigate();
    const params = useParams();
    return <AccountingCls navigate={navigate} params={ params } user={user} />;
}
