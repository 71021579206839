import React from 'react';

import { Grid, Column, MultiSelect, Theme, TextInput, DatePicker, DatePickerInput } from "@carbon/react";
import { NavigateFunction, useNavigate, useSearchParams } from 'react-router-dom';
import "./PartyPlaces.scss";
import { PartyPlaceList } from './PartyPlaceList';
import { NumberInput } from '@carbon/react';
import { Button } from '@carbon/react';

interface PartyPlacesState {
    init: boolean,
    zip: string,
    date: string,
    minGuests: string,
    event_types?: string[],
    editZip: boolean,
    editType: boolean
}
export class PartyPlacesCls extends React.Component<{ navigate: NavigateFunction, searchParams: URLSearchParams }, PartyPlacesState> {
    state: PartyPlacesState = {
        init: false,
        zip: "",
        date: "",
        minGuests: "",
        editZip: false,
        editType: false,
    }

    changeType(evt: any) {
        let event_types = evt.selectedItems;
        this.setState({ event_types });
        this.props.navigate(`?zip=${this.state.zip}&date=${this.state.date}&guests=${this.state.minGuests}&type=${encodeURIComponent(event_types.join(","))}`)
    }

    changeGuests(evt: any, { value }) {
        this.setState({ minGuests: value });
        this.props.navigate(`?zip=${this.state.zip}&date=${this.state.date}&guests=${value}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
    }

    changeZip(event: any) {
        this.setState({ zip: event.target.value });
        if (event.target.value.match(/\d{5}/)) {
            this.props.navigate(`?zip=${event.target.value}&date=${this.state.date}&guests=${this.state.minGuests}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
        }
    };

    changeDate(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let dateStr = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        dateStr += "/" + (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        dateStr += "/" + newDate.getFullYear() + "";
        this.setState({ date: dateStr });
        this.props.navigate(`?zip=${this.state.zip}&date=${dateStr}&guests=${this.state.minGuests}&type=${encodeURIComponent(this.state.event_types!.join(","))}`)
    };

    componentDidMount() {
        let sp = this.props.searchParams;
        let eventStr = sp.get("type") ?? "";
        this.setState({ 
            zip: sp.get("zip") ?? "", 
            date: sp.get("date") ?? "", 
            minGuests: sp.get("guests") ?? "",
            event_types: eventStr.length === 0 ? [] : eventStr.split(","),
            init: true 
        });
        document.getElementById("root")!.style.backgroundColor = "#eee";
    }

    componentWillUnmount() {
        document.getElementById("root")!.style.backgroundColor = "#F4F4F4";
    }

    render() {
        if (!this.state.init) return <></>;
        return (
            <main className="partyPlaces">
                <Theme theme="white" className="cds--pt-white">
                    <Grid>
                        <Column sm={4} md={8} lg={12} style={{ margin: "1rem 1rem 0rem 1rem" }}>
                            {!this.state.editZip && <div>
                                <span style={{
                                    fontSize: "var(--cds-body-compact-01-font-size, 0.875rem)"
                                }}>Zip code</span>: <Button
                                    style={{ marginTop: "-1rem" }} 
                                    kind="ghost" onClick={() => { 
                                        this.setState({ editZip: true });
                                        setTimeout(() => { document.getElementById("search-zip")?.focus(); }, 0);
                                    }}>{this.state.zip }</Button>
                            </div>}
                            { this.state.editZip && <div style={{ flex: "0 0 6rem", margin: "0rem 1rem 1rem 0rem" }}>
                                <TextInput
                                    id="search-zip"
                                    labelText="Zip code"
                                    value={this.state.zip}
                                    onChange={this.changeZip.bind(this)} 
                                    onBlur={() => {
                                        this.setState({ editZip: false });
                                    }}
                                />
                            </div>}
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                                {this.state.event_types && (
                                    <div style={{ flex: "1 0 10rem", margin: "0rem 1rem 1rem 0rem" }}>
                                        <MultiSelect
                                            label={this.state.event_types.join(", ")}
                                            id="event_type_selector"
                                            titleText="Type of event"
                                            initialSelectedItems={this.state.event_types}
                                            items={[
                                                "Kid party"
                                                , "Adult party"
                                                , "Wedding"
                                                , "Anniversary"
                                                , "Work party"
                                                , "Other"
                                            ]}
                                            itemToString={item => item ?? ''}
                                            selectionFeedback="fixed"
                                            value={this.state.event_types}
                                            // helperText="Types of events that would be appropriate at this location"
                                            onChange={this.changeType.bind(this)}
                                        />
                                    </div>
                                )}
                                <div style={{ flex: "0 0 5rem", margin: "0rem 1rem 1rem 0rem" }}>
                                    <DatePicker
                                        datePickerType="single"
                                        value={this.state.date}
                                        onChange={this.changeDate.bind(this)}
                                        minDate={new Date().setDate(new Date().getDate() -1)}
                                    >
                                        <DatePickerInput
                                            id="search-date"
                                            labelText="Date"
                                            // helperText="mm/dd/yyyy"
                                        />
                                    </DatePicker>
                                </div>
                                <div style={{ flex: "0 0 5rem", margin: "0rem 1rem 1rem 0rem" }}>
                                    <NumberInput
                                        min={1}
                                        label="Number of guests"
                                        value={this.state.minGuests}
                                        onChange={this.changeGuests.bind(this)}
                                        allowEmpty={true}
                                    />
                                </div>
                            </div>
                        </Column>
                    </Grid>
                </Theme>
                {this.state.init && <PartyPlaceList 
                    zip={this.state.zip} 
                    date={this.state.date} 
                    event_types={this.state.event_types!}
                    minGuests={this.state.minGuests}
                /> }
            </main>
        )
    }
}

export default function PartyPlaces() {
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    return <PartyPlacesCls navigate={navigate} searchParams={searchParams} />
}
