import React, { Component } from "react";
import { ClickableTile, Column, Grid, SkeletonPlaceholder } from "@carbon/react";
import { TimeUtil } from "../../../../util/TimeUtil";
import { IPTSearchResult, SearchV1 } from "../../services/SearchV1";
import "./PartyPlaceList.scss";

interface PartyPlaceListProps {
    zip: string,
    date: string,
    event_types: string[],
    minGuests: string
}

interface PartyPlaceListState {
    searchResults?: IPTSearchResult[]
}

export class PartyPlaceList extends Component<PartyPlaceListProps, PartyPlaceListState> {
    state: PartyPlaceListState = {

    }

    componentDidMount(): void {
        this.refresh();
    }

    componentDidUpdate(prevProps: Readonly<PartyPlaceListProps>, prevState: Readonly<PartyPlaceListState>, snapshot?: any): void {
        if (JSON.stringify(this.props) !== JSON.stringify(prevProps)) {
            this.refresh();
        }
    }

    async refresh() {
        let results = await SearchV1.search(this.props.zip, this.props.date, this.props.minGuests, this.props.event_types);
        this.setState({ searchResults: results })
    }

    render() {
        let qStr = `?zip=${this.props.zip}&date=${this.props.date}&guests=${this.props.minGuests}&type=${encodeURIComponent(this.props.event_types.join(","))}`;

        return <Grid>
            <Column sm={4} md={8} lg={12}>
                <h1>Results</h1>
                {!this.state.searchResults && <>
                    <div style={{ marginTop: "1rem" }} />
                    <div><SkeletonPlaceholder style={{width: "100%"}} /></div>
                    <div style={{marginTop: "1rem"}}><SkeletonPlaceholder style={{width: "100%"}} /></div>
                </>}
                {this.state.searchResults?.length === 0 && <>
                    <div style={{ marginTop: "1rem" }} />
                    <p>Sorry, no locations have availability near the location at the time you specified.</p>
                    <p>If you know of another location that would like to be listed here, <a 
                        href="mailto:info@partytaken.com">email us!</a></p>
                </>}
                <div className="ptResults">
                    {this.state.searchResults?.map((result: any) => (
                        <div key={result.location_id}>
                            <ClickableTile 
                                key={result.location_id}
                                href={`/partyplace${qStr}&location=${result.location_id}`}
                            >
                                <div style={{display: "flex"}}>
                                    { (result.locImages?.[0] && (
                                        <img
                                            style={{ 
                                                margin: "-1rem",
                                                marginRight: "1rem",
                                                width: "12rem", height: "9rem", 
                                                objectFit: "cover", objectPosition: "left top"
                                            }}
                                            src={`/api/pt/image/${result.locImages[0].src}`} alt="" 
                                        />
                                    )) || <div style={{
                                        margin: "-1rem",
                                        flex: "0 0 auto", marginRight: "1rem", backgroundColor: "#dedede", 
                                        width: "12rem", height: "9rem"
                                    }}/>}
                                    <div style={{flex: "1 1 auto", paddingBottom: "3rem"}}>
                                        <div><strong className="resultTitle">{result.name}</strong></div>
                                        {result.searchdescription.split("\n").map((line, idx) => <div key={`line${idx}`}>{line}</div>)}
                                    </div>
                                </div>
                            </ClickableTile>
                            <div style={{marginTop: "-3.25rem", marginLeft: "13rem"}}>
                                <div>
                                    <div className="timeGroup">
                                        {SearchV1.getSearchTimes(result.areas).map(timeInfo => {
                                            let timeStr = TimeUtil.convR24toR12Str(timeInfo);
                                            return <button onClick={() => {
                                                document.location.href = `/partyplace${qStr}&location=${result.location_id}&time=${timeStr}`
                                            }}>{timeStr}</button>
                                        })}
                                    </div>
                                </div>
                                {/* <ButtonSet>
                                {this.getSearchTimes(result.areas).map(timeInfo => {
                                    let timeStr = TimeUtil.convR24toR12Str(timeInfo);
                                    return <Button key={timeStr} size="sm" kind="secondary" style={{
                                        padding: ".5rem 1rem",
                                        width: "11rem"
                                    }}
                                    href={`/reserve${qStr}&location=${result.location_id}&time=${timeStr}`}
                                    >{timeStr}</Button>
                                    })}
                                </ButtonSet> */}
                            </div>
                        </div>
                    ))}
                </div>
            </Column>
        </Grid>

    }
}