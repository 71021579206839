import React from 'react';

import ModalBasic from '../../../../components/ModalBasic';
import { 
    FormItem,
    FileUploaderDropContainer,
    FileUploaderItem,
    Layer
} from "@carbon/react";
import { IImageDesc } from '../../services/LocationV1';
import { Button } from '@carbon/react';
import { NextOutline, PreviousOutline } from '@carbon/react/icons';
import { ButtonSet } from '@carbon/react';

interface ModalPhotosProps {
    open: boolean
    heading: string
    maxImages?: number
    onSubmit: (photos: IImageDesc[]) => Promise<void>
    onClose: () => Promise<void>
    photos: Array<{ desc: string, src: string }>
}

interface ModalPhotosState {
    files: Array<{ desc: string, src: string }>
}

export class ModalPhotos extends React.Component<ModalPhotosProps, ModalPhotosState> {
    state: ModalPhotosState = {
        files: []
    }

    addPropPhotos(
        existingArray: Array<{ desc: string, src: string }>,
        srcArray: Array<{ desc: string, src: string }>, 
    ) {
        let newArray: Array<{ desc: string, src: string }> = JSON.parse(JSON.stringify(existingArray));
        for (const file of srcArray) {
            if (!newArray.some(newFile => file.src === newFile.src )) {
                while (typeof this.props.maxImages !== "undefined" && newArray.length >= this.props.maxImages) {
                    newArray.shift();
                }
                newArray.push({ desc: file.desc, src: file.src });
            }
        }
        if (JSON.stringify(this.state.files) !== JSON.stringify(newArray)) {
            this.setState({ files: newArray });
        }
    }

    componentDidMount(): void {
        this.addPropPhotos([], this.props.photos);
    }

    componentDidUpdate(prevProps: Readonly<ModalPhotosProps>, prevState: Readonly<ModalPhotosState>, snapshot?: any): void {
        if (this.state.files.length === 0) {
            this.addPropPhotos(JSON.parse(JSON.stringify(this.state.files)), this.props.photos);
        }
    }

    imgButtons(idx: number) {
        return <ButtonSet>
        {idx > 0 && <Button 
            hasIconOnly
            renderIcon={PreviousOutline}
            iconDescription="Move previous"
            kind="ghost"
            size="sm"
            onClick={() => {
                let newArray: Array<{ desc: string, src: string }> = JSON.parse(JSON.stringify(this.state.files));
                let f = newArray.splice(idx, 1)[0];
                newArray.splice(idx-1, 0, f);
                this.setState({ files: newArray });
            }} />}
        {idx === 0 && <div style={{
            backgroundColor: "white",
            padding: ".4rem .25rem",
        }} id="primaryImageIndicator">Primary image</div>}
        {idx > 0 && <Button 
            kind="ghost"
            size="sm"
            onClick={() => {
                let newArray: Array<{ desc: string, src: string }> = JSON.parse(JSON.stringify(this.state.files));
                let f = newArray.splice(idx, 1)[0];
                newArray.unshift(f);
                this.setState({ files: newArray });
            }}
            >Make primary</Button>
        }
        {idx < this.state.files.length-1 && <Button 
            hasIconOnly
            renderIcon={NextOutline}
            iconDescription="Move next"
            kind="ghost"
            size="sm"
            onClick={() => {
                let newArray: Array<{ desc: string, src: string }> = JSON.parse(JSON.stringify(this.state.files));
                let f = newArray.splice(idx, 1)[0];
                newArray.splice(idx+1, 0, f);
                this.setState({ files: newArray });
            }} />}
        </ButtonSet>;
    }

    render() {
        let { children, onSubmit, ...rest } = this.props;
        return <ModalBasic 
            primaryButtonText="Save"
            isDisabled={ () => false }
            onSubmit={async () => {
                await this.props.onSubmit(this.state.files);
            }}
            onReset={async () => {
                this.addPropPhotos([], this.props.photos);
            }}
            hasScrollingContent={true}
            size="lg"
            { ...rest }
        >
            <FormItem>
                <p className="cds--file--label">
                    {this.props.maxImages === 1 ? <>Upload file</> : <>Upload files</>}
                </p>
                <p className="cds--label-description">
                    Photo aspect ratio should be 4:3. Max file size is 500kb. Supported file types are .jpg and .png.
                </p>
                <Layer>

                <FileUploaderDropContainer
                    accept={[
                        'image/jpeg',
                        'image/png'
                    ]}
                    // innerRef={{
                    //     current: '[Circular]'
                    // }}
                    labelText={this.props.maxImages === 1 ? "Drag and drop a file here or click to upload" : "Drag and drop files here or click to upload"}
                    multiple
                    name=""
                    onAddFiles={(_evt, {addedFiles}) => {
                        let self = this;
                        let numToProcess = addedFiles.length;
                        let results: Array<{ desc: string, src: string }> = JSON.parse(JSON.stringify([]));
                        const onLoadEnd = function(e: any, idx: number, f: any) {
                            results[idx].desc = f.name;
                            results[idx].src = e.target.result;
                            --numToProcess;
                            if (numToProcess === 0) {
                                self.addPropPhotos(JSON.parse(JSON.stringify(self.state.files)), results);
                            }
                        }
                        for (const f of addedFiles) {
                            let idx = results.length;
                            results.push({ desc: "", src: "" });
                            let r = new FileReader();
                            r.onloadend = (e) => onLoadEnd(e, idx, f);
                            r.readAsDataURL(f);
                        }
                        return true;                    
                    }}
                    // onChange={(a,b,c) => {
                    //     console.log("YYY");
                    //     console.log(a);
                    //     console.log(b);
                    //     console.log(c);
                    // }}
                    // tabIndex={0}
                />
                {this.state.files && this.state.files.length > 0 && (
                    <div style={{marginTop: "2rem", display:"flex", flexWrap: "wrap" }} >
                        {this.state.files?.map((file, idx) => (<div style={{marginBottom: "1rem"}} >
                            <div style={{
                                border: idx === 0 ? "solid #333 2px" : undefined,
                                marginRight: ".5rem", 
                                // marginBottom: ".5rem",
                                padding: ".5rem",
                                position: "relative",
                            }}>
                                {this.imgButtons(idx)}
                                <img
                                    style={{ 
                                        width: "20rem", height: "15rem", 
                                        objectFit: "cover", objectPosition: "left top"
                                    }}                     
                                    alt={file.desc} src={file.src.startsWith("data") ? file.src : `/api/pt/image/${file.src}`} 
                                    aria-describedby='primaryImageIndicator'/>
                            </div>
                            <FileUploaderItem name={file.desc} status="edit" 
                                onDelete={() => {
                                    let results: Array<{ desc: string, src: string }> = JSON.parse(JSON.stringify(this.state.files));
                                    results.splice(idx, 1);
                                    this.setState({ files: results });
                                }} 
                                iconDescription="Cancel import" />
                        </div>))}
                    </div>
                )}
                </Layer>
                <div className="cds--file-container cds--file-container--drop" />
            </FormItem>
        </ModalBasic>
    }
}