import React from 'react';
import './App.scss';
import SiteCampTaken from './sites/camptaken/SiteCampTaken';
import SiteClubAndBooster from './sites/clubandbooster/SiteClubAndBooster';
import SiteHomeroomParent from './sites/homeroomparent/SiteHomeroomParent';
import SitePartyTaken from './sites/partytaken/SitePartyTaken';
import { Config } from "./Config";

const App: React.FC = () => {
    let siteKey = Config.getSiteKey();
    if (siteKey === "hrp") {
        return <SiteHomeroomParent />
    } else if (siteKey === "club") {
        return <SiteClubAndBooster />
    } else if (siteKey === "pt") {
        return <SitePartyTaken />;
    } else if (siteKey === "ct") {
        return <SiteCampTaken />;
    } else {
        return <></>
    }
}

export default App;
