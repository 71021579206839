import React from "react";
import { Params, useParams, useSearchParams } from "react-router-dom";
import { URLSearchParams } from 'url';

import { 
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbSkeleton,
    Column,
    DatePicker,
    DatePickerInput,
    Grid,
    ListItem,
    UnorderedList
} from "@carbon/react";
import { IPTLocation, LocationV1, PTReservationTime } from "../../services/LocationV1";
import { AccountV1, IPTAccount } from "../../services/AccountV1";
import ReservationV1, { BasicResInfo, IReservation } from "../../services/ReservationV1";
import { DateUtil } from "../../../../util/DateUtil";
import LocationScheduleTable from "./LocationScheduleTable";
import { TimeUtil } from "../../../../util/TimeUtil";

interface LocationScheduleProps {
    params: Params<string>
    searchParams: URLSearchParams
}

interface LocationScheduleState {
    account?: IPTAccount
    location?: IPTLocation
    reservation?: Partial<IReservation>,
    date: string,
    resInfo: BasicResInfo[]
}

class LocationScheduleCls extends React.Component<LocationScheduleProps, LocationScheduleState> {
    state: LocationScheduleState = {
        date: "",
        resInfo: []
    }

    componentDidMount() {
        (async () => {
            let location = await LocationV1.getLocation(this.props.params["locationId"]!);
            let account = await AccountV1.getAccount(location.account_id);
            let resId = this.props.searchParams.get("reservation") ?? null;
            let reservation = resId ? (await ReservationV1.get(resId)) : undefined;
            this.setState({
                account,
                location,
                reservation,
                date: reservation?.date || DateUtil.todayStrPadded("/",true)
            }, () => {
                this.refreshDate();
            })
        })();
    }

    componentDidUpdate(prevProps: Readonly<LocationScheduleProps>, prevState: Readonly<LocationScheduleState>, snapshot?: any): void {
        if (prevState.date !== this.state.date) {
            this.refreshDate();
        }
    }

    async refreshDate() {
        if (this.state.location) {
            this.setState({
                resInfo: await LocationV1.getReservations(this.state.location.id, this.state.date)
            });
        }
    }

    renderTimes(times: PTReservationTime[]) {
        if (!times) return <></>
        times.sort((a, b) => {
            let partsA = a.start.split(":");
            let partsB = b.start.split(":");
            return parseInt(partsA[0]) - parseInt(partsB[0]);
        })
        if (!times || times.length === 0) {
            return <div>No regularly reservable times on this day</div>;
        } else {
            return <UnorderedList>
            {times?.map((time: PTReservationTime) => (
                <ListItem key={time.start + "-" + time.end}>{TimeUtil.conv24to12Str(time.start)}&ndash;{TimeUtil.conv24to12Str(time.end)}</ListItem>
            ))}
            </UnorderedList>
        }
    }

    render() {
        let loaded = this.state.account && this.state.location;
        // let timeInfo:PTReservationTime[] = [];
        // if (this.state.date && this.state.location?.reservableDays) {
        //     let d = new Date(this.state.date);
        //     if (this.state.date in this.state.location.reservableDays) {
        //         timeInfo = this.state.location.reservableDays[this.state.date];
        //     } else if (this.state.location.reservableSchedule) {
        //         timeInfo = this.state.location.reservableSchedule[d.getDay()];
        //     }
        // }
        return <>
            <div style={{ marginTop: "3rem" }} />
            <Grid>
                <Column sm={4} md={8} lg={16}>
                    {(!this.state.account || !this.state.location) && <BreadcrumbSkeleton />}
                    {this.state.account && this.state.location && !this.state.reservation && <Breadcrumb>
                        <BreadcrumbItem href="/account/accounts">Accounts</BreadcrumbItem>
                        <BreadcrumbItem href={`/account/home/${this.state.account.id}`}>
                            {this.state.account.name}
                        </BreadcrumbItem>
                        <BreadcrumbItem href={`/account/home/${this.state.account.id}/location/home/${this.state.location.id}`}>
                            {this.state.location.name}
                        </BreadcrumbItem>
                        <BreadcrumbItem 
                            href={`/account/home/${this.state.account.id}/location/home/${this.state.location.id}/schedule`}
                            isCurrentPage>Schedule</BreadcrumbItem>
                    </Breadcrumb>}
                    {this.state.account && this.state.location && this.state.reservation && <Breadcrumb>
                        <BreadcrumbItem href="/auth/reservations">
                            Taken parties
                        </BreadcrumbItem>
                        <BreadcrumbItem
                            href={`/auth/reservation/${this.state.reservation.id}`}
                        >
                            {this.state.reservation.name}
                        </BreadcrumbItem>
                        <BreadcrumbItem 
                            href={`/account/home/${this.state.account.id}/location/home/${this.state.location.id}/schedule?reservation=${this.state.reservation.id}`}
                            isCurrentPage>Schedule</BreadcrumbItem>
                    </Breadcrumb>}
                </Column>
            </Grid>
            <main className="Location">
                <div style={{ marginTop: "1rem" }} />
                {this.state.date !== "" && <Grid>
                    <Column sm={4} md={8} lg={16}>
                        <div style={{ display: "flex" }} >
                            <div style={{ flex: "1 1 auto" }} />
                            <div style={{ flex: "0 0 auto" }}>
                                <DatePicker 
                                    datePickerType="single" dateFormat="Y-m-d" value={this.state.date}
                                    onChange={(val) => {
                                        this.setState({
                                            date: DateUtil.dateStrPadded(new Date(val[0]), "/", true)
                                        });
                                    }}
                                >
                                    <DatePickerInput 
                                        id="schedule-date"
                                        labelText="Schedule date" 
                                        placeholder="mm/dd/yyyy" 
                                    />
                                </DatePicker>
                            </div>
                            <div style={{ flex: "1 1 auto" }} />
                        </div>
                        <div style={{marginTop: "2rem"}} />
                        <div style={{ display: "flex" }} >
                            <div style={{ flex: "1 1 auto" }} />
                            <div style={{ flex: "0 0 auto" }}>
                                { loaded && <LocationScheduleTable
                                    date={this.state.date}
                                    account={this.state.account!}
                                    location={this.state.location!}
                                    reservation={this.state.reservation}
                                    resInfo={this.state.resInfo}
                                    onUpdate={async () => {
                                        this.refreshDate();
                                    }}
                                    onAvailability={async () => {
                                        
                                    }}
                                />}
                            </div>
                            <div style={{ flex: "1 1 auto" }} />
                        </div>
                    </Column>
                </Grid>}
            </main>
        </>
    }
}

export default function LocationSchedule() {
    const params = useParams();
    let [searchParams] = useSearchParams();
    return <LocationScheduleCls params={params} searchParams={searchParams} />;
}

