import React from 'react';
import { IUserBasic } from '../models/IUser';
import { Theme, Grid, Column } from "@carbon/react";

import "./HomeNav.scss";
import { SiteKey } from '../models/Site';
import { Link } from 'react-router-dom';
import UserV1 from '../services/UserV1';
import { OverflowMenu, OverflowMenuItem } from '@carbon/react';
import { UserAvatar } from "@carbon/react/icons";
import UserService from '../sites/common/services/UserService';

interface HomeNavProps {
    siteKey: SiteKey
}

interface HomeNavState {
    user?: IUserBasic
}

export default class HomeNav extends React.Component<HomeNavProps, HomeNavState> {
    state: { user?: IUserBasic } = {
    }

    componentDidMount() {
        (async () => {
            UserV1.addUserListener((user?: IUserBasic) => {
                this.setState({ user });
            })
            let user = await UserV1.getUser();
            this.setState({ user });
        })();
    }

    async logout() {
        await UserV1.logout();
        this.setState({ user: undefined })
    }

    render() {
        let user_type = this.state.user?.user_type;
        return (
            <Theme theme="g90">
                <nav className="homeNavBar">
                    <Grid>
                        <Column sm={4} md={3} lg={8} style={{ marginTop: "1rem" }}>
                            <Link className="clean" to="/auth/reservations">
                                {this.props.siteKey === "pt" && "Taken Parties"}
                                {this.props.siteKey === "ct" && "Taken Camps"}
                            </Link>
                            { user_type && (
                                user_type.includes("ADMIN")
                                || user_type.includes("OWNER")
                                || user_type.includes("MANAGER")
                                || user_type.includes("STAFF")
                            ) && (
                                <>
                                    <span className="spacer">|</span>
                                    <Link className="clean" to="/account/accounts">
                                        Accounts
                                    </Link>
                                </>
                            )}
                        </Column>
                        <Column
                            sm={4}
                            md={5}
                            lg={8}
                            className="rightNav"
                            style={{ marginTop: "1rem", marginBottom: "1rem" }}
                        >
                            {this.state.user && (
                                <>
                                    <span className="userEmail" style={{ opacity: ".75", marginRight: "16px" }}>
                                        {this.state.user!.email}
                                    </span>
                                    <OverflowMenu
                                        iconDescription="Profile"
                                        renderIcon={UserAvatar}
                                        flipped={true}
                                        style={{ margin: "-1rem -.75rem -1.25rem 0rem" }}
                                        size="lg"
                                    >
                                        <OverflowMenuItem
                                            itemText="Change password"
                                            onClick={() => {
                                                UserService.changePassword();
                                            }}
                                        />
                                        <OverflowMenuItem itemText="Sign out" onClick={this.logout.bind(this)} />
                                    </OverflowMenu>
                                    {/* <span style={{opacity: ".75", marginRight:"16px"}}>
                            {this.state.user!.email}</span> <a className="clean" href="#logout" onClick={this.logout.bind(this)}>Sign Out</a> */}
                                </>
                            )}
                            {!this.state.user && (
                                <>
                                    <a className="clean" href="/login">
                                        Sign In<span className="spacer">/</span>Sign Up
                                    </a>
                                </>
                            )}
                        </Column>
                    </Grid>
                </nav>
                <div style={{ clear: "both" }} />
            </Theme>
        );
    }
}