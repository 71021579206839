import React from 'react';

import {
    TextInput
} from "@carbon/react";
import ModalBasic from './ModalBasic';

interface ModalTextInputProps {
    open: boolean
    heading: string
    modalLabel?: string
    buttonText?: string,
    helperText?: string,
    inputLabel: string,
    dialogText?: React.ReactElement
    onClose: () => Promise<void>
    onText: (val: string) => Promise<void>
}

interface ModalTextInputState {
    currentText: string
}

export class ModalTextInput extends React.Component<ModalTextInputProps, ModalTextInputState> {
    state: ModalTextInputState = {
        currentText: ""
    }

    render() {
        let { children, heading, ...rest } = this.props;
        return <ModalBasic 
            heading={heading}
            primaryButtonText={this.props.buttonText || "Submit"}
            isDisabled={() => (this.state.currentText || "").length === 0}
            onSubmit={async () => {
                await this.props.onText(this.state.currentText);
            }}
            onReset={async () => {
                this.setState({ currentText: "" });
            }}
            { ...rest }
        >
            {this.props.dialogText && <div style={{marginBottom: "1.5rem"}}>{this.props.dialogText}</div>}
            {<TextInput
                id="dlgTxtInput"
                data-modal-primary-focus
                labelText={ this.props.inputLabel }
                helperText={this.props.helperText}
                value={this.state.currentText}
                onChange={(evt) => { this.setState({ currentText: evt.target.value }) }}
                onKeyDown={async (evt: KeyboardEvent) => {
                    if (evt.key === "Enter") {
                        await this.props.onText(this.state.currentText);
                        this.props.onClose();            
                    }
                }}
            />}
        </ModalBasic>
    }
}