import { Config } from "../Config";
import { IUserBasic } from "../models/IUser";

export type UserListener = (user?: IUserBasic) => any

export default class UserV1 {
    static user?: IUserBasic;
    static listeners: UserListener[] = [];

    static addUserListener(f: UserListener) {
        this.listeners.push(f);
    }

    static removeUserListener(f: UserListener) {
        this.listeners = this.listeners.filter(l => l !== f);
    }

    static setUser(user?: IUserBasic) {
        this.user = user;
        for (const l of this.listeners) {
            l(user);
        }
        return this.user;
    }

    static userP?: Promise<IUserBasic | undefined>;
    static async getUser() : Promise<IUserBasic | undefined> {
        if (this.user) {
            return this.user;
        } else if (this.userP) {
            return this.userP;
        } else {
            return this.userP = new Promise<IUserBasic | undefined>(async (resolve, reject) => {
                let result = await fetch(`/api/${Config.getSiteKey()||"common"}/user`);
                if (result.status === 204 || result.statusText === "No Content") {
                    resolve(undefined);
                } else {
                    resolve(this.setUser(await result.json()));
                }
            })
        }
    }

    static async logout() {
        await fetch(`/api/common/user/logout`);
        this.setUser(undefined);
    }

    static async emailsToIds(emails: string[]) : Promise<string[]> {
        let result = await fetch(`/api/common/user/emails_to_ids?emails=${encodeURIComponent(emails.join(","))}`);
        if (result.status === 200) {
            let data = await result.json();
            return data.ids;
        } else {
            throw new Error(result.statusText);
        }
    }

    static async idsToEmails(ids: string[]) : Promise<string[]> {
        let result = await fetch(`/api/common/user/ids_to_emails?ids=${encodeURIComponent(ids.join(","))}`);
        if (result.status === 200) {
            let data = await result.json();
            return data.emails;
        } else {
            throw new Error(result.statusText);
        }
    }
}
