import React from "react";
import {
    Button,
    DatePicker, DatePickerInput,
    DefinitionTooltip,
    NumberInput,
    RadioTile,
    SkeletonPlaceholder,
    TileGroup
} from '@carbon/react';
import { IPTSearchResult, SearchV1 } from "../../services/SearchV1";
import { TimeUtil, range24 } from "../../../../util/TimeUtil";
import { PTPackage } from "../../services/LocationV1";
import { PaypalButton } from "../../../../components/PaypalButton";
import ReservationV1 from "../../services/ReservationV1";
import { OrderResponseBody } from "@paypal/paypal-js";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DateUtil } from "../../../../util/DateUtil";
import "./Booking.scss";

let today = DateUtil.today();

interface BookingProps {
    loggedIn: boolean,
    searchParams: URLSearchParams,
    searchResult: IPTSearchResult | null,
    date: string,
    minGuests: string,
    timeStr?: string | null,
    onDate: (date: string) => Promise<void> | void
    onTime: (time: string) => Promise<void> | void
    onQuery: (qStr: string) => Promise<void> | void
    onReserve: () => Promise<void> | void
    onLogin: () => Promise<void> | void
}

interface BookingState {
    pkgId?: string,
    totalGuests: number
}
export class BookingCls extends React.Component<BookingProps & { navigate: NavigateFunction }, BookingState> {
    public static calculateCharges(pkg: PTPackage, totalGuests: number) {
        let costs: Array<{ label: string, value: number}> = [];
        let downPayments: Array<{ label: string, value: number}> = [];
        costs.push({ label: "Package charge", value: pkg.base.cost });
        downPayments.push({ label: "Package down payment", value: pkg.base.downPayment });
        if (pkg.addon) {
            let extraGuests = Math.max(0, totalGuests-pkg.base.capacity);
            costs.push({ label: "Guest charge", value: pkg.addon.cost * extraGuests });
            downPayments.push({ label: "Guest down payment", value: pkg.addon.downPayment * extraGuests });
        }

        let subtotal = costs.reduce((acc, curr) => acc + curr.value, 0);
        let serviceFee = Math.max(subtotal * .03, 5);
        costs.push({ label: "Service fee", value: serviceFee });
        downPayments.push({ label: "Service fee", value: serviceFee });
        return { costs, downPayments };
    }
    
    state: BookingState = {
        totalGuests: 0
    }

    componentDidMount(): void {
        let reqGuests = parseInt(this.props.minGuests) || 0;
        let sp = this.props.searchParams;
        let pkgId = sp.get("pkg") ?? "";
        let pkg = this.props.searchResult?.packages.find(pkg => pkg.id === pkgId);
        let totalGuests = !pkg ? reqGuests : parseInt(sp.get("tg") ?? ((pkg && ""+pkg.base.capacity) || "0"));
        this.setState({ pkgId, totalGuests })
    }

    changeDate(pickerDateStr: string) {
        let newDate = new Date(pickerDateStr);
        let dateStr = ((newDate.getMonth() + 1) < 10 ? "0" : "") + (newDate.getMonth() + 1);
        dateStr += "/" + (newDate.getDate() < 10 ? "0" : "") + newDate.getDate();
        dateStr += "/" + newDate.getFullYear() + "";
        this.props.onDate(dateStr);
    };

    render() {
        let sr = this.props.searchResult;

        const availTimes: range24[] = this.props.searchResult ? SearchV1.getSearchTimes(this.props.searchResult.areas) : [];
        let searchCapacity = 0;
        if (this.props.searchResult) {
            if (this.props.timeStr) {
                searchCapacity = SearchV1.getTimeCapacity(
                    this.props.searchResult.areas,
                    TimeUtil.convR12StrToR24(this.props.timeStr)
                );
            } else {
                searchCapacity = SearchV1.getDayCapacity(
                    this.props.searchResult.areas);
            }
        }
        let costs: Array<{ label: string, value: number }> = [];
        let downPayments: Array<{ label: string, value: number }> = [];
        let chosenPackage: PTPackage | null = null;
        if (this.state.pkgId && this.props.searchResult) {
            chosenPackage = this.props.searchResult.packages.find(pkg => pkg.id === this.state.pkgId)!;
            let { costs: rcosts, downPayments: rdownPayments } = BookingCls.calculateCharges(chosenPackage, this.state.totalGuests);
            costs = rcosts;
            downPayments = rdownPayments;
            if (!chosenPackage.addon) {
                searchCapacity = Math.min(searchCapacity, chosenPackage.base.capacity);
            }
        }
        if (searchCapacity < this.state.totalGuests) {
            this.setState({ totalGuests: searchCapacity });
        }

        let amountDue = downPayments.reduce((acc, curr) => acc + curr.value, 0);
        let remainBalance = costs.reduce((acc, curr) => acc + curr.value, 0)-amountDue;

        return <div className="bookingTile">
            <h2 style={{ marginTop: "0rem" }}>Request a new reservation</h2>
            <div style={{ marginTop: "1rem" }} />

            <DatePicker
                minDate={today}
                datePickerType="single"
                value={this.props.date}
                onChange={this.changeDate.bind(this)}
            >
                <DatePickerInput
                    id="search-date"
                    labelText="Date"
                    helperText="mm/dd/yyyy"
                />
            </DatePicker>
            {!sr && <>
                <div style={{ marginTop: "1rem" }} />
                No availability on {this.props.date}.
            </>}
            {sr && <>
                <div style={{ marginTop: "1rem" }} />
                <div className={this.props.timeStr?"validTime":"invalidTime"}>
                    <div id="timeGroupLabel" className="cds--label">Event time</div><br />
                    <div onKeyDown={async (evt: React.KeyboardEvent<HTMLDivElement>) => {
                        let idx = -1;
                        if (this.props.timeStr) {
                            idx = availTimes.findIndex(availTime => TimeUtil.convR24toR12Str(availTime) === this.props.timeStr);
                        }
                        if (evt.key === "ArrowUp" || evt.key === "ArrowLeft") {
                            if (idx === -1) {
                                idx = availTimes.length - 1;
                            } else if (idx > 0) {
                                --idx;
                            }
                        } else if (evt.key === "ArrowDown" || evt.key === "ArrowRight") {
                            if (idx < availTimes.length - 1) {
                                ++idx;
                            }
                        }
                        if (idx !== -1) {
                            let timeStr = TimeUtil.convR24toR12Str(availTimes[idx]);
                            this.props.onTime(timeStr);
                        }
                    }}
                        tabIndex={0}
                        aria-labelledby="timeGroupLabel"
                        className="timeGroup"
                        role="radiogroup"
                        aria-activedescendant={this.props.timeStr ? `time_${this.props.timeStr}` : undefined}
                    >
                        {availTimes.map(timeInfo => {
                            let timeStr = TimeUtil.convR24toR12Str(timeInfo);
                            return <button key={`time_${timeStr}`} id={`time_${timeStr}`} tabIndex={-1}
                                role="radio" aria-checked={timeStr === this.props.timeStr}
                                className={`${timeStr === this.props.timeStr ? "selected" : ""}`}
                                onClick={() => {
                                    this.props.onTime(timeStr);
                                }}
                            >{timeStr}</button>
                        })}
                    </div>
                </div>
                <div style={{ marginTop: "1rem" }} />
                {!sr && <SkeletonPlaceholder />}
                {sr && <TileGroup
                    className={chosenPackage ? "validPackage" : "invalidPackage"}
                    legend="Select a package"
                    name="package"
                    onChange={(pkgId) => {
                        let pkg = sr!.packages.find(pkg => pkg.id === pkgId);
                        this.setState({ pkgId, totalGuests: pkg!.base.capacity })
                        this.props.onQuery(`pkg=${pkgId}&tg=${pkg!.base.capacity}`);
                    }}
                    valueSelected={this.state.pkgId}
                >
                    {sr.packages.map((pkg, idx) => (
                        <RadioTile
                            id={`pkg-option-${idx}`}
                            key={`pkg-option-${idx}`}
                            style={{
                                marginBottom: '.5rem'
                            }}
                            value={pkg.id}
                        >
                            <strong>{pkg.label}</strong>
                            <div>Cost: {`$${pkg.base.cost.toFixed(2)}`}</div>
                            {pkg.base.capacity <= searchCapacity && <div>Included guests: {pkg.base.capacity}</div>}
                            {pkg.base.capacity > searchCapacity && <div>
                                Included guests: <DefinitionTooltip
                                    openOnHover
                                    definition="This number was reduced because there is not enough space available at this time"
                                ><del>{pkg.base.capacity}</del> <ins>{searchCapacity}</ins>
                                </DefinitionTooltip>
                            </div>}

                            {pkg.addon && <div style={{ fontStyle: "italic" }}>Charge per additional guest: {`$${pkg.addon.cost.toFixed(2)}`}</div>}
                        </RadioTile>
                    ))}
                </TileGroup>}
                <NumberInput
                    className={this.state.totalGuests > 0 ? "validGuests": "invalidGuests"}
                    label="Total guests"
                    id="totalGuestCount"
                    max={searchCapacity}
                    value={this.state.totalGuests}
                    allowEmpty={true}
                    onChange={(_e: any, { value }) => {
                        let totalGuests = parseInt(value) || 0;
                        this.setState({ totalGuests }, () => {
                            this.props.onQuery(`pkg=${this.state.pkgId}&tg=${totalGuests}`);
                        })
                    }}
                />
                <div style={{ marginTop: "1rem" }} />
                {chosenPackage && <DefinitionTooltip 
                    openOnHover 
                    align="top"
                    definition={costs.filter(cost => cost.value > 0).map(cost => `${cost.label}: $${cost.value.toFixed(2)}`).join(", \n")}
                >Total: ${costs.reduce((acc, curr) => acc + curr.value, 0).toFixed(2)}</DefinitionTooltip>}

                <div style={{ marginTop: ".5rem" }} />
                {chosenPackage && <DefinitionTooltip 
                    openOnHover align="right-bottom"
                    definition={downPayments.filter(cost => cost.value > 0).map(cost => `${cost.label}: $${cost.value.toFixed(2)}`).join(", \n")}
                ><strong>Due today: ${downPayments.reduce((acc, curr) => acc + curr.value, 0).toFixed(2)}</strong></DefinitionTooltip>}

                {chosenPackage && 
                    <div style={{ marginTop: ".5rem" }}>Remaining balance: ${remainBalance.toFixed(2)}</div>
                }
                {chosenPackage && this.props.timeStr && this.state.totalGuests > 0 && <p style={{marginTop: "1.5rem"}} className="note">
                    An authorization in the amount of ${amountDue.toFixed(2)} will 
                    be placed upon payment. The payment will be charged when the reservation is accepted
                    by the event location.</p>}
                <div style={{ marginTop: "1rem" }} />
                {!this.props.loggedIn && <Button
                    disabled={!chosenPackage || !this.props.timeStr || this.state.totalGuests === 0}
                    onClick={this.props.onLogin}
                >Login to reserve &gt;</Button>}
                {this.props.loggedIn && <PaypalButton 
                    disabled={!chosenPackage || !this.props.timeStr || this.state.totalGuests === 0}
                    intent="AUTHORIZE" 
                    amount={amountDue}
                    onApprove={async (orderId: string, authDetails: OrderResponseBody) => {
                        let { id: resId } = await ReservationV1.create({
                            location_id: sr!.location_id,
                            date: this.props.date,
                            timeStr: this.props.timeStr!,
                            packageId: this.state.pkgId!,
                            totalGuests: this.state.totalGuests,
                            orderId,
                            authDetails
                        })
                        this.props.navigate(`/auth/reservation/${resId}`);
                    }}
                />}
            </>}
        </div>
    }
}

export function Booking(props: Readonly<BookingProps>) {
    let navigate = useNavigate();
    return <BookingCls navigate={navigate} {...props} />
}
